// Translated
// Migrated
<template>
  <div
    id="side-menu"
    class="side-menu__wrap pb-12"
    :class="classes"
  >
    <div
      class="blue-container side-menu"
      :class="{ 'side-menu-collapsed': collapsed, 'side-menu-collapsible': collapsible, 'side-menu-overflow': overflow }"
    >
      <div
        v-if="collapsible"
        class="sidemenu-collapse"
        @click="collapsed = !collapsed"
      >
        <button class="btn btn-link btn-white">
          <fa
            class="text-white"
            :icon="collapsed ? 'chevron-down' : 'chevron-up'"
          />
        </button>
      </div>
      <div
        v-show="activeSubmenu"
        class="submenu-overlay"
        @click="setSubmenu(null)"
      />
      <h5 class="side-menu-title mt-6">
        <span data-i18n="sideMenuTitle">
          {{ $t('sideMenuTitle') }}
        </span>
      </h5>
      <h5
        v-for="(toplink, i) in tripTypesSidebar.toplinks"
        :key="i"
        class="side-menu-title"
      >
        <span
          class="side-menu-icon"
          :class="{ 'side-menu-icon-star': toplink.icon === 'star' }"
        >
          <fa :icon="icon(toplink.icon)" />
        </span>
        <nuxt-link
          class="inline"
          :to="toplink.url"
          @click="menuStore.closeSideMenuActive()"
        >
          {{ toplink.name }}
        </nuxt-link>
      </h5>
      <hr class="mx-auto" />
      <template v-if="sideMenuActive">
        <LazyAlgoliaSearchBar
          :autofocus="false"
          :header="true"
          @search="menuStore.closeSideMenuActive()"
          @hide-search-bar="menuStore.closeSideMenuActive()"
        />
      </template>
      <ul
        v-for="(triptypesGroup, index) in tripTypesSidebar.content"
        :key="index"
        class="side-menu-section"
        :class="{ 'with-border': Object.keys(triptypesGroup.categories).length === 1 }"
      >
        <li :class="activeSubmenu === triptypesGroup.name ? 'active' : 'non'">
          <span
            v-if="hasSubCategories(triptypesGroup)"
            class="side-menu-title category-title"
          >{{ triptypesGroup.name }}</span>
          <template v-else>
            <a
              class="side-menu-title category-title"
              :href="triptypesGroup.categories[Object.keys(triptypesGroup.categories)[0]].category_links[0].url"
              @click.prevent="setSubmenu(triptypesGroup.name)"
            >{{ triptypesGroup.name }}</a>
            <LazySubmenu
              v-if="activeSubmenu === triptypesGroup.name"
              class="py-0 side-menu-subcategories"
              :title="triptypesGroup.name"
              :title-translation="triptypesGroup?.name_translationkey"
              :trips="triptypesGroup.categories[Object.keys(triptypesGroup.categories)[0]].trips"
              :category-links="triptypesGroup.categories[Object.keys(triptypesGroup.categories)[0]].category_links"
              @close="setSubmenu(null)"
            />
          </template>
        </li>
        <li v-if="hasSubCategories(triptypesGroup)">
          <ul class="side-menu-subcategories">
            <li
              v-for="(category, idx) in triptypesGroup.categories"
              :key="idx"
              :class="activeSubmenu === category.name ? 'active' : 'non'"
            >
              <a
                :href="category.category_links[0].url"
                @click.prevent="setSubmenu(category.name)"
              >{{ category.name }}</a>
              <LazySubmenu
                v-if="activeSubmenu === category.name"
                :title="category.name"
                :trips="category.trips"
                :category-links="category.category_links"
                @close="setSubmenu(null)"
              />
            </li>
          </ul>
        </li>
      </ul>
      <div class="hamburgerMenuWrapper">
        <ul
          v-for="(navItem, index) in topNavbar"
          :key="index"
          class="side-menu-section"
          :class="{ 'mt-2 !-mb-3': navItem.mobileview && sideMenuActive }"
        >
          <li
            v-if="navItem.mobileview && sideMenuActive"
            :class="activeHamburgerMenu === navItem.label ? 'active' : 'non'"
            class="-mt-2"
            @click="activeHamburgerMenu = navItem.label"
          >
            <div class="!flex justify-between items-center side-menu-title mt-2">
              <a v-if="navItem.submenu && navItem.submenu.length">
                {{ navItem.label }}
              </a>
              <nuxt-link
                v-else
                :to="navItem.link"
                @click="menuStore.closeSideMenuActive()"
              >
                {{ navItem.label }}
              </nuxt-link>
              <button
                v-if="activeHamburgerMenu === navItem.label"
                class="btn btn-link submenu-close text-white py-0"
                @click.stop="activeHamburgerMenu = null"
              >
                <fa
                  icon="times"
                  width="18"
                />
              </button>
            </div>
            <ul
              v-if="navItem.submenu && activeHamburgerMenu === navItem.label"
              class="bg-white w-full py-4"
            >
              <li
                v-for="(item, i) in navItem.submenu"
                :key="i"
                class="py-1 px-5"
              >
                <div
                  v-if="item.label === 'linebreak'"
                  class="dropdown-divider"
                />
                <a
                  v-else-if="item.absoluteLink"
                  class="dropdown-item px-4 !text-dark"
                  :href="item.link"
                  target="_blank"
                >{{ item.label }}</a>
                <nuxt-link
                  v-else
                  class="px-4 !text-dark"
                  :to="item.link"
                  @click="menuStore.closeSideMenuActive()"
                >
                  {{ item.label }}
                </nuxt-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { faList, faGlobe } from '@fortawesome/pro-solid-svg-icons'
import { faCalendar } from '@fortawesome/pro-duotone-svg-icons'

const icons = {
  faList,
  faGlobe,
}

export default defineNuxtComponent({
  setup () {
    const menuStore = useMenuStore()

    return {
      menuStore,
    }
  },

  name: 'Sidebar',

  props: {
    collapsible: {
      type: Boolean,
      default: false,
    },

    preventLayoutingClasses: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      activeSubmenu: null,
      activeHamburgerMenu: null,
      collapsed: this.collapsible,
      overflow: false,
      timer: null,
    }
  },

  computed: {
    ...mapState(useRootStore, {
      topNavbar: 'topNavbar',
      tripTypesSidebar: 'tripTypesSidebar',
    }),

    ...mapState(useMenuStore, {
      sideMenuActive: 'sideMenuActive',
    }),

    classes () {
      let c = []
      if (!this.preventLayoutingClasses) {
        c = ['xl:w-3/12', 'lg:w-3/12', 'hidden', 'lg:block', 'mr-auto', 'float-left', 'px-0']
      }
      if (this.sideMenuActive) {
        c.push('mobile-side-menu__active')
      }
      return c
    },
  },

  watch: {
    collapsible (v) {
      if (v) {
        this.collapsed = true
      } else {
        this.collapsed = false
      }
    },

    collapsed (v) {
      if (v) {
        this.overflow = false
        this.setSubmenu(null)
      } else {
        this.timer = window.setTimeout(() => { this.overflow = true }, 1000)
      }
    },
  },

  mounted () {
    window.addEventListener('resize', this.resizeListener)
  },

  beforeUnmount () {
    clearTimeout(this.timer)
    window.removeEventListener('resize', this.resizeListener)
  },

  methods: {
    hasSubCategories (group) {
      return Object.keys(group.categories).length > 1
    },

    resizeListener () {
      if (window.innerWidth > 768) {
        this.menuStore.closeSideMenuActive()
      }
    },

    icon (icon) {
      if (icon === 'calendar') {
        return faCalendar
      }

      const iconClass = 'fa' + icon.split('-').map(s => s.charAt(0).toUpperCase() + s.slice(1)).join('')

      return icons[iconClass] || icon
    },

    setSubmenu (submenu) {
      this.activeSubmenu = submenu
    },
  },
})
</script>
